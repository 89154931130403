import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { JsonLd } from "../components/JsonLd.js"

const SEO = ({ title, description, image, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          keywords,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        keywords: keywords || keywords,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.keywords && <meta property="og:keywords" content={seo.keywords} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <html lang="da" />
          </Helmet>
          <noscript key={`noscript`} id={`gatsby-noscript`}>
              This site works best with JavaScript enabled.
          </noscript>
          <JsonLd>
                {{
                    '@context': 'http://schema.org',
                    '@type': 'LocalBusiness',
                    'name': 'easymed SE',
                    'url': 'https://easymed.com.se',
                    'description': 'Easymed säljer CT och röntgen röntgentillbehör och tillbehör til strålterapi, mr scanning, utraljud och nuklearmedicin till skandinaviska hospitaler.',
                    'telephone': '004527205354',
                    'image': 'https://easymed.com.se/easymed_logo.png',
                    'priceRange': 'Call for information',
                    'address': {
                        '@type': 'PostalAddress',
                        'streetAddress': "Ordrup Jagtvej 37",
                        'addressLocality': 'Charlottenlund',
                        'postalCode': '2920',
                        'addressCountry': 'DK',
                    },
                }}
            </JsonLd>
        </>
      )
    }}
  />
)

export { SEO as SearchEngineOp };

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  keywords: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  keywords: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultImage: image
        siteUrl: url
        keywords: keywords
      }
    }
  }
`